import exgif from "./assets/images/ex.gif";
import sggif from "./assets/images/sg.gif";
import tm1gif from "./assets/images/tm1.gif";
import youtube from "./assets/images/youtube .gif";

export const projectData = [
  {
    image1: `${tm1gif}`,
    projectName: `Task Master`,
    projectDesc: `The app is task Manager app built using MUI, React, Mongodb,Node, Express. Here you can create task with uploading images and managed your daily routine. `,
    live: "https://task-master-eosin.vercel.app/",
    github: "https://github.com/NidhiSharma63/task-master",
  },
  {
    image1: `${sggif}`,
    projectName: `Snapgram`,
    projectDesc: `This is a intagram clone that is have made using MERN stack with typescript. It provides all the instagram functionality and it is fully responsive.`,
    live: "https://snapgram-green.vercel.app/",
    github: "https://github.com/NidhiSharma63/snapgram",
  },
  {
    image1: `${youtube}`,
    projectName: `Youtube Clone`,
    projectDesc: `I created this app using React React-Player Rapid API TypeScript. Here you can search the videos, and can save into playlist or watchlater`,
    live: "https://nidhisharma63.github.io/youtube-clone/",
    github: "https://github.com/NidhiSharma63/youtube-clone",
  },

  {
    image1: `${exgif}`,
    projectName: `Fitness App`,
    projectDesc: `This is a fitness-exercise app. I have
    created this app by using React, material ui and rapid-api. This app
    shows more than a thousand exercises with their video to make you fit.`,
    live: "https://nidhisharma63.github.io/FITNESS-EXERCISE-REACT-APP/",
    github: "https://github.com/NidhiSharma63/FITNESS-EXERCISE-REACT-APP",
  },
];
