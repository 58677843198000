import React from "react";

const Errorpage = () => {
  return (
    <div className="error">
      <p>Page does not exist</p>
    </div>
  );
};

export default Errorpage;
